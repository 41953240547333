import React, { useState } from 'react';
import { Segment, Icon, Header } from 'semantic-ui-react'
import useSWR from 'swr';
import { fetcher } from '../lib/fetch'


const ReleaseItemList = ({items}) => (
    <ul>
        {items.map((d) => (<li key={d}>{d}</li>))}
    </ul>
)

const ReleaseNote = ({version, changes, knownIssues, expanded: defaultExpanded}) => {
    const [expanded, setExpanded] = useState(defaultExpanded)

    const toggleExpanded = (e) => {
        setExpanded(!expanded)
    }
    const icon = expanded ? "angle double up" : "angle double down"

    return (
        <Segment>
            <Header as="h4" onClick={toggleExpanded}>Release {version} <Icon name={icon}/></Header>
            {expanded && (
                <div>
                    <Header as="h5">Changes</Header>
                    <ReleaseItemList items={changes}/>
                    {knownIssues.length > 0 && (
                        <div>
                            <Header as="h5">Known issues</Header>
                            <ReleaseItemList items={knownIssues}/>
                        </div>
                    )}
                </div>
            )}
        </Segment>
    )
}


const ReleaseNotes = () => {
    const {data, error} = useSWR('/api/v1/about/changes', fetcher, {revalidateOnFocus: false})
    if (error) {
        return <ReleaseNote version="" changes={["Release notes currently not available"]} knownIssues={[]}/>
    }
    if (!data) {
        return <Segment><Header as="h4"><Icon name="spinner" loading /></Header></Segment>
    }
    return (
    <div>
        {data.map((d, i) => (<ReleaseNote key={d.version} expanded={i === 0} {...d}/>))}
    </div>
)
}

export default ReleaseNotes;
