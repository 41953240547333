import { useState } from 'react';

const useEditable = () => {

    const [isEdit, setIsEdit] = useState(false);
    const startEdit = () => {
        setIsEdit(true);
    };
    const stopEdit = () => {
        setIsEdit(false);
    }

    return [
        isEdit,
        startEdit,
        stopEdit
    ]
};

export default useEditable;
