import React, { useState } from 'react';
import { Dropdown, Form, Header, Icon, Message, Segment, Table } from 'semantic-ui-react';
import useSWR, { mutate } from 'swr';
import { HeaderWithHelp, HelpPopup } from '../Components/HelpText';
import { fetcher } from '../lib/fetch';
import toastMaster from '../Toast';
import useForm from '../lib/useForm';
import useEditable from '../lib/useEditable';

const ShowRow = ({ title, children }) => (
    <Table.Row>
        <Table.Cell>{title}</Table.Cell>
        <Table.Cell>{children}</Table.Cell>
    </Table.Row>
);

const ShowRowIfTruthy = ({ title, value }) => {
    if (!value) {
        return null;
    }
    return (
        <ShowRow title={title}>
            <strong>{value}</strong>
        </ShowRow>
    );
};

function isNumberWithin(val, min, max) {
    if (isNaN(val)) {
        return false;
    }
    if (val < min) {
        return false;
    }
    if (val > max) {
        return false;
    }
    return true;
}

async function ApplyOutputSettings(id, port, speed, format) {
    const newPort = port || ''; // string
    const newSpeed = speed || 0; // integer
    const newFormat = format || "";  // string
    const body = {
        id: id,
        name: '',
        type: '',
        port: newPort,
        clients: 0,
        speed: newSpeed,
        format: newFormat,
        error: '',
    };
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    };
    console.log('ApplyOutputSettings', body);
    return fetch('/api/v1/outputs/' + id, options);
}

async function onSubmitHandler(id, values, setIsSubmitting, setSubmitError, setIsSubmitSuccess, stopEdit) {
    setSubmitError(false);
    setIsSubmitSuccess(false);
    setIsSubmitting(true);
    const result = await ApplyOutputSettings(id, values.port, values.speed, values.format);

    if (!result.ok) {
        result.json().then((body) => {
            console.log('submit error', body);
            if (body.error) {
                setSubmitError(body.error);
            } else {
                // GOA errors don't have a 'error' field
                setSubmitError(JSON.stringify(body));
            }
            setIsSubmitSuccess(false);
            toastMaster.open({
                type: "error",
                message: "Failed to apply output configuration",
                duration: 3000,
            })
        });
    } else {
        console.log('submit success');
        setIsSubmitSuccess(true);
        setSubmitError('');
        mutate('/api/v1/outputs');
        setTimeout(() => {
            stopEdit();
        }, 50);
        toastMaster.open({
            type: "success",
            message: "Output configuration success",
            duration: 3000,
        })
    }
    setIsSubmitting(false);
};

function validatePorts(values) {
    let errors = {};
    if (!isNumberWithin(values.port, 1024, 9999)) {
        errors.port = 'Must be between 1024 and 9999';
    }
    console.log('Validation of ports', values, errors);
    return errors;
}

const EditPort = ({ id, port, stopEdit }) => {
    const [submitError, setSubmitError] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

    const onSubmit = () => onSubmitHandler(id, values, setIsSubmitting, setSubmitError, setIsSubmitSuccess, stopEdit);
    const initial = { port, speed: 0, format: '' };
    const { values, errors, handleChange, handleSubmit } = useForm(onSubmit, validatePorts, initial);

    const onChange = (e) => {
        handleChange(e.target.name, e.target.value);
    };

    return (
        <ShowRow title="Port">
            <Form size="small" onSubmit={handleSubmit}>
                <Form.Group>
                    <Form.Input name="port" error={errors.port} value={values.port} type="number" onChange={onChange} />

                    <Form.Button icon positive type="submit" disabled={isSubmitting}>
                        Apply&nbsp;
                        {isSubmitting && <Icon name="circle notched" loading />}
                        {isSubmitSuccess && <Icon name="checkmark" />}
                    </Form.Button>

                    <Form.Button icon color="grey" type="button" disabled={isSubmitting} onClick={stopEdit}>
                        Cancel&nbsp;
                        {isSubmitting && <Icon name="circle notched" loading />}
                    </Form.Button>
                </Form.Group>

                {submitError && <Message icon="exclamation" content={submitError} />}
            </Form>
        </ShowRow>
    );
};

const validBaudRates = [9600, 19200, 38400, 57600, 115200];
const slowBaudRates = [9600, 19200];

function validateBaudRate(values) {
    let errors = {};
    if (!validBaudRates.includes(parseInt(values.speed))) {
        errors.speed = 'Must be one of ' + validBaudRates.join(', ');
    }
    console.log('Validation of baud rate', values, errors);
    return errors;
}

const baudRateOptions = validBaudRates.map((rate) => ({
    key: rate,
    text: rate,
    value: rate,
}));

const EditBaudRate = ({ id, speed, stopEdit }) => {
    const [submitError, setSubmitError] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

    const onSubmit = () => onSubmitHandler(id, values, setIsSubmitting, setSubmitError, setIsSubmitSuccess, stopEdit);
    const initial = { speed, port: "", format: ''};
    const { values, errors, handleChange, handleSubmit } = useForm(onSubmit, validateBaudRate, initial);

    const onChange = (e, data) => {
        //handleChange(e.target.name, e.target.value);
        handleChange("speed", data.value);
    };

    return (
        <ShowRow title="Baud Rate">
            <Form size="small" onSubmit={handleSubmit}>
                <Form.Group>
                    <Dropdown
                        button
                        options={baudRateOptions}
                        value={values.speed}
                        onChange={onChange}
                        error={errors.speed}
                    />

                    <Form.Button icon positive type="submit" disabled={isSubmitting}>
                        Apply&nbsp;
                        {isSubmitting && <Icon name="circle notched" loading />}
                        {isSubmitSuccess && <Icon name="checkmark" />}
                    </Form.Button>

                    <Form.Button icon color="grey" type="button" disabled={isSubmitting} onClick={stopEdit}>
                        Cancel&nbsp;
                        {isSubmitting && <Icon name="circle notched" loading />}
                    </Form.Button>

                    <BaudRateWarning speed={values.speed} />
                </Form.Group>

                {submitError && <Message icon="exclamation" content={submitError} />}
            </Form>
        </ShowRow>
    );
};

function validateFormat(values) {
    let errors = {};
    if (!isNumberWithin(values.format, 0, 6)) {
        errors.port = 'Must be between 0 and 6';
    }
    console.log('Form validation', values, errors);
    return errors;
}

const formatDisabled = '0';
// list of available formats for the user to select. ordered by what seems logical to the user
// capacity is the ranking of the required data volume. lower value means lower capacity requirement
const availableFormats = [
    {"id": '0', "name": "Disabled", "capacity": -1},
    {"id": '3', "name": "WL - Serial V2", "capacity": 3},
    {"id": '1', "name": "WL - Serial V1 and V2", "capacity": 4},
    {"id": '6', "name": "PD4", "capacity": 1},
    {"id": '2', "name": "PD6", "capacity": 2},
    // Disabled: {"id": '4', "name": "V2, velocity only", "capacity": -1},
    // Disabled: {"id": '5', "name": "V2, position only", "capacity": -1},
];
// create the array that DropDown needs
const formatOptions = availableFormats.map((format) => ({
    key: format.id,
    text: format.name,
    value: format.id,
}));

// array with available formats that have capacity > 0
const formatNamesWithCapacity = availableFormats.filter((format) => format.capacity > 0);
// sort formats by data/volume capacity. lowest capacity first
const formatNameSortedByCapacity = formatNamesWithCapacity.sort((a, b) => a.capacity - b.capacity).map((format) => format.name);


const BaudRateWarning = ({speed}) => {
    if (!slowBaudRates.includes(speed)) {
        return null
    }

    const description = "Baud rates " + slowBaudRates.join(", ") + " might drop messages at high ping rates " +
    "(up to 15 Hz at low altitudes) depending on protocol in use.\n\n" +
    "Protocols ordered by data volume (least to most): " + formatNameSortedByCapacity.join(", ") + ".\n\n" +
    "Use a higher baud rate to avoid dropping messages.\n\n" +
    "An error will be shown on this page when messages are being dropped."

    return (
            <span>Baud rate {speed} might drop messages
            <HelpPopup text={description} />
        </span>
    )
}


const EditFormat = ({ id, format, stopEdit }) => {
    const [submitError, setSubmitError] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

    const onSubmit = () => onSubmitHandler(id, values, setIsSubmitting, setSubmitError, setIsSubmitSuccess, stopEdit);
    const initial = { format, port: "", speed: 0};
    const { values, errors, handleChange, handleSubmit } = useForm(onSubmit, validateFormat, initial);

    const onChange = (e, data) => {
        handleChange('format', data.value);
    };
    return (
        <ShowRow title="Protocol Format">
            <Form size="small" onSubmit={handleSubmit}>
                <Form.Group>
                    <Dropdown
                        button
                        options={formatOptions}
                        value={values.format}
                        onChange={onChange}
                        error={errors.format}
                    />
                    <Form.Button icon positive type="submit" disabled={isSubmitting}>
                        Apply&nbsp;
                        {isSubmitting && <Icon name="circle notched" loading />}
                        {isSubmitSuccess && <Icon name="checkmark" />}
                    </Form.Button>

                    <Form.Button icon color="grey" type="button" disabled={isSubmitting} onClick={stopEdit}>
                        Cancel&nbsp;
                        {isSubmitting && <Icon name="circle notched" loading />}
                    </Form.Button>
                </Form.Group>
                {submitError && <Message icon="exclamation" content={submitError} />}
            </Form>
        </ShowRow>
    );
};

const ValueWithEditIcon = ({ title, value, editable, startEdit, children }) => (
    <ShowRow title={title}>
        <strong>{value}</strong>
        &nbsp;
        {editable && <Icon name="pencil" onClick={startEdit} />}
        {children}
    </ShowRow>
);

const ShowValueOrEditPort = ({ id, title, value, editable }) => {
    const [isEdit, startEdit, stopEdit] = useEditable();
    return (
        <>
            {isEdit ? (
                <EditPort id={id} port={value} stopEdit={stopEdit} />
            ) : (
                <ValueWithEditIcon title={title} value={value} editable={editable} startEdit={startEdit} />
            )}
        </>
    );
};

const ShowValueOrEditBaudRate = ({ id, title, value, editable }) => {
    const [isEdit, startEdit, stopEdit] = useEditable();
    return (
        <>
            {isEdit ? (
                <EditBaudRate id={id} speed={value} stopEdit={stopEdit} />
            ) : (
                <ValueWithEditIcon title={title} value={value} editable={editable} startEdit={startEdit}>
                    <BaudRateWarning speed={value} />
                </ValueWithEditIcon>
            )}
        </>
    );
};

const ShowValueOrEditFormat = ({ id, title, initialValue, value, editable }) => {
    const [isEdit, startEdit, stopEdit] = useEditable();
    return (
        <>
            {isEdit ? (
                <EditFormat id={id} format={initialValue} stopEdit={stopEdit} />
            ) : (
                <ValueWithEditIcon title={title} value={value} editable={editable} startEdit={startEdit} />
            )}
        </>
    );
};

const ShowProtocol = ({ id, title, port, type, format, speed, clients, error }) => {
    const portIsEditable = id === 'tcp_pd6' || id === "tcp_pd4";
    const isSerial = id === 'serial';
    const formatValue = isSerial ? format.split(',')[0] : format;
    const formatId = isSerial ? format.split(',')[1] || formatDisabled : formatDisabled;
    return (
        <Segment attached>
            <Header as="h4">{title}</Header>
            <Table basic="very" collapsing>
                <Table.Body>
                    {port &&
                        <ShowValueOrEditPort
                            id={id}
                            title="Port"
                            value={port}
                            editable={portIsEditable}
                        />
                    }
                    <ShowRowIfTruthy title="Type" value={type} />
                    {format && (
                        <ShowValueOrEditFormat
                            id={id}
                            title="Protocol Format"
                            value={formatValue}
                            initialValue={formatId}
                            editable={isSerial}
                        />
                    )}
                    {clients >= 0 && (
                        <ShowRow title="Connected clients">
                            <strong>{clients}</strong>&nbsp;
                            {clients > 0 && <Icon name="check circle" color="green" />}
                        </ShowRow>
                    )}
                    {speed > 0 && (
                        <ShowValueOrEditBaudRate
                            id={id}
                            title="Baud Rate"
                            value={speed}
                            editable={isSerial}
                        />
                    )}
                    <ShowRowIfTruthy title="Error" value={error} />
                </Table.Body>
            </Table>
        </Segment>
    );
};

const OutputInner = () => {
    const { data, error } = useSWR('/api/v1/outputs/', fetcher, { refreshInterval: 2000, dedupingInterval: 100 });
    if (error) {
        return <p>Error getting outputs</p>;
    }
    if (!data) {
        return <p>Loading..</p>;
    }
    return (
        <>
            {data.map((el) => (
                <ShowProtocol
                    key={el.id}
                    id={el.id}
                    title={el.name}
                    type={el.type}
                    port={el.port}
                    speed={el.speed}
                    format={el.format}
                    clients={el.clients}
                    error={el.error}
                />
            ))}
        </>
    );
};

const OutputRoute = () => (
    <Segment>
        <HeaderWithHelp
            as="h3"
            heading="Outputs"
            help="Configure ports and format for the available API protocols. Disable serial protocol if not used to minimize latency for other protocols."
        />
        <OutputInner />
    </Segment>
);

export default OutputRoute;
